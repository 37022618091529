@import '../../../styles/var';

.accountProtection {
    position: relative;
    padding: 120px 137px 180px 137px;
    overflow: hidden;
    @media(max-width: $md1+px) {
        padding: 120px 114px 140px 114px;
    }
    @media(max-width: $md2+px) {
        padding: 100px 80px 120px 80px;
    }
    @media(max-width: $md3+px) {
        padding-bottom: 30px;
    }
    @media(max-width: $md4+px) {
        padding: 60px 50px 30px 50px;
    }
    @media(max-width: $md6+px) {
        padding: 40px 20px 20px 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 800px;
        @media(max-width: $md1+px) {
            max-width: 666px;
        }
        @media(max-width: $md3+px) {
            max-width: 100%;
        }
        .title {
            
        }
        .text {
            margin-top: 40px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 2;
        width: 486px;
        top: 90px;
        right: 100px;
        @media(max-width: $md1+px) {
            width: 380px;
            right: 60px;
        }
        @media(max-width: $md2+px) {
            width: 350px;
            right: 40px;
            top: 70px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            width: 400px;
            top: unset;
            right: unset;
        }
        @media(max-width: 600px) {
            width: 220px;
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 455px;
        @media(max-width: $md1+px) {
            width: 380px; 
        }
        @media(max-width: $md2+px) {
            width: 350px;
        }
        @media(max-width: $md3+px) {
            transform: rotate(90deg);
            top: unset;
            bottom: -40px;
        }   
        @media(max-width: 600px ) {
            width: 240px;
        }
    }
}