@import '../../../styles/var';

.underBanner {
    position: relative;
    overflow: hidden;
    margin-top: 120px;
    padding: 149px 137px;
    @media (max-width: $md1+px) {
        padding: 120px 115px;
    }
    @media (max-width: $md2+px) {
        padding: 100px 80px;
    }
    @media (max-width: $md3+px) {
        padding-bottom: 0;
    }
    @media (max-width: $md4+px) {
        margin-top: 140px;
        padding: 80px 50px 0 50px;
    }
    @media (max-width: $md6+px) {
        margin-top: 130px;
        padding: 40px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 663px;
        @media (max-width: $md1+px) {
            max-width: 552px;
        }
        @media (max-width: $md3+px) {
            max-width: 100%;
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .subtitle {
            margin-top: 40px;
            color: #39219C;
            @media (max-width: $md1+px) {
                margin-top: 30px;
            }
            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 50px;
        bottom: 0;
        width: 674px;
        @media (max-width: $md1+px) {
            width: 550px;
            right: 40px;
        }
        @media (max-width: $md2+px) {
            width: 490px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            right: unset;
            bottom: unset;
            width: 562px;
        }
        @media (max-width: 800px) {
            width: 400px;
        }
        @media (max-width: 600px) {
            width: 340px;
        }
        @media (max-width: $md6+px) {
            margin-top: 40px;
            width: 284px;
        }
    }
}