@import '../../../styles/var';

.additional {
    position: relative;
    .title {
    }
    .cards {
        position: relative;
        // margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        // @media(max-width: $md1+px) {
        //     margin-top: 40px;
        // }
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .card {
            position: relative;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 40px 30px 70px 40px;
            @media(max-width: $md1+px) {
                padding: 40px 30px 50px 40px;
            }
            @media(max-width: $md3+px) {
                padding: 30px 30px 40px 30px;
            }
            @media(max-width: $md4+px) {
                padding: 20px 50px 30px 40px;
            }
            @media(max-width: $md6+px) {
                padding: 20px 20px 40px 20px;
            }
            &:nth-child(1) {
                background: transparent;
                padding: 40px 0 0 0;
                text-align: left;
                @media(max-width: $md3+px) {
                    padding-top: 30px;
                }
                @media(max-width: $md4+px) {
                    padding: 0 0 20px 0;
                }
            }
            &Icon {
                width: 80px;
                @media(max-width: $md1+px) {
                    width: 60px;
                }
                @media(max-width: $md6+px) {
                    width: 50px;
                }
            }
            &Title {
                color: #000000;
                margin: 40px 0 20px 0;
            }
            &Text {

            }
        }
    }
}