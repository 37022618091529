@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 100px;
    overflow: hidden;
    background: #0566EC;
    border-radius: 20px;
    padding: 100px 80px 200px 80px;
    @media(max-width: $md1+px) {
        margin-top: 80px;
        padding: 100px 80px 136px 80px;
    }
    @media(max-width: $md3+px) {
        padding-bottom: 0;
    }
    @media(max-width: $md4+px) {
        padding: 60px 40px 0 40px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
        padding: 20px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            color: #FFFFFF;
            @media(max-width: $md4+px) {
                font-size: 24px;
            }
        }
        .subtitle {
            font-weight: 500;
            color: #FFFFFF;
            margin: 10px 0 40px 0;
            @media(max-width: $md1+px) {
                margin-top: 8px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 20px;
            }
        }
        .text {
            color: rgba(255, 255, 255, 0.8);
            max-width: 774px;
            @media(max-width: $md1+px) {
                max-width: 586px;
            }
        }
        .btn {
            margin-top: 60px;
            @media(max-width: $md6+px) {
                margin-top: 40px;
            }
        }
    }
    .woman {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: 236px;
        width: 415px;
        @media(max-width: $md1+px) {
            width: 326px;
            right: 196px;
        }
        @media(max-width: $md2+px) {
            width: 280px;
            right: 160px;
        }
        @media(max-width: $md3+px) {
            width: 311px;
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            right: unset;
            bottom: unset;
            transform: translateX(-40px);
        }
        @media(max-width: 700px) {
            width: 162px;
            margin-top: 30px;
            transform: translateX(-25px);
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        width: 732px;
        bottom: 0;
        right: 28px;
        @media(max-width: $md1+px) {
            width: 610px;
            right: 23px;
        }
        @media(max-width: $md2+px) {
            width: 530px;
            right: 20px;
        }
        @media(max-width: $md3+px) {
            width: 680px;
            left: -400px;
            right: 0;
            margin: 0 auto;
        }   
        @media(max-width: 700px) {
            width: 400px;
            left: -260px;
        }
    }
    .globe {
        position: absolute;
        z-index: 2;
        width: 519px;
        top: -100px;
        right: -160px;
        @media(max-width: $md1+px) {
            width: 450px;
            top: -80px;
            right: -120px;
        }
        @media(max-width: $md2+px) {
            width: 400px;
        }
        @media(max-width: $md3+px) {
            width: 300px;
            top: unset;
            right: -80px;
            bottom: 230px;
        }   
        @media(max-width: 700px) {
            width: unset;
            height: 155px;
            bottom: 124px;
        }
    }
    .rocket {
        position: absolute;
        z-index: 4;
        width: 205px;
        top: 84px;
        right: 95px;
        @media(max-width: $md1+px) {
            top: 70px;
            right: 79px;
            width: 170px;
        }
        @media(max-width: $md2+px) {
            width: 150px;
            top: 140px;
            right: 50px;
        }
        @media(max-width: $md3+px) {
            top: unset;
            left: 0;
            right: -300px;
            bottom: 266px;
            margin: 0 auto;
        }
        @media(max-width: 700px) {
            bottom: 138px;
            width: 90px;
            right: -170px;
        }
    }
    .arrow {
        position: absolute;
        z-index: 5;
        width: 559px;
        bottom: -98px;
        right: 23px;
        @media(max-width: $md1+px) {
            bottom: -82px;
            right: 19px;
            width: 450px;
        }
        @media(max-width: $md2+px) {
            width: 400px;
            bottom: -70px;
            right: 10px;
        }
        @media(max-width: $md3+px) {
            left: 0;
            right: -150px;
            margin: 0 auto;
        }
        @media(max-width: 700px) {
            width: 230px;
            bottom: -40px;
            right: -70px;
        }
    }
}