@import '../../../styles/var';

.whatTypes {
    position: relative;
    padding: 80px 80px 100px 80px;
    border-radius: 20px;
    overflow: hidden;
    @media(max-width: $md1+px) {
        padding: 60px 60px 80px 60px;
    }
    @media(max-width: $md3+px) {
        padding: 40px 40px 60px 40px;
    }
    @media(max-width: $md4+px) {
        padding: 40px 30px 60px 30px;
    }
    @media(max-width: $md6+px) {
        width: calc(100% + 40px);
        transform: translateX(-20px);
        padding: 30px 20px 40px 20px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/AccountTypesPage/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .title {
        text-align: center;
        position: relative;
        z-index: 10;
    }
    .cards {
        position: relative;
        z-index: 10;
        margin-top: 60px;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }
        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
        .card {
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.85);
            padding: 40px 40px 100px 40px;
            @media(max-width: $md2+px) {
                padding: 30px 30px 60px 30px;
            }
            @media(max-width: $md3+px) {
                transform: none!important;
            }
            @media(max-width: $md6+px) {
                padding: 20px 20px 40px 20px;
            }
            &:nth-child(4) {
                transform: translateX(calc(473px / 2 + 10px));
                @media(max-width: $md1+px) {
                    transform: translateX(calc(367px / 2 + 10px));
                }
                @media(max-width: $md2+px) {
                    transform: translateX(calc(333px / 2 + 10px));
                }
            }  
            &:nth-child(5) {
                transform: translateX(calc(473px / 2 + 10px));
                @media(max-width: $md1+px) {
                    transform: translateX(calc(367px / 2 + 10px));
                }
                @media(max-width: $md2+px) {
                    transform: translateX(calc(333px / 2 + 10px));
                }
            }
            &Header {
                display: flex;
                justify-content: space-between;
                font-family: $roboto!important;
                color: #0566EC;
                .cardTitle {

                }
                .cardPrice {

                }
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin-top: 40px;
                li {
                    position: relative;
                    padding-left: 30px;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 18px;
                        height: 18px;
                        background-image: url('../../../assets/img/AccountTypesPage/check.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center center;
                        @media(max-width: $md1+px) {
                            width: 16px;
                            height: 16px;
                            top: 2px;
                        }
                    }
                }
            }
        }
    }
}