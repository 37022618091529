@import '../../../styles/var';

.platformGlance {
    position: relative;
    overflow: hidden;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 44px 76px;
    @media (max-width: $md1+px) {
        padding: 44px 50px;
    }
    @media (max-width: $md2+px) {
        padding: 40px 50px 40px 20px;
    }
    @media (max-width: $md3+px) {
        padding: 100px 80px 0 80px;
        justify-content: unset;
        align-items: unset;
        flex-direction: column-reverse;
    }
    @media (max-width: $md4+px) {
        padding: 80px 50px 0 50px;
    }
    @media (max-width: $md6+px) {
        padding: 40px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 663px;
        @media (max-width: $md1+px) {
            max-width: 552px;
        }
        @media (max-width: $md3+px) {
            max-width: 100%;
        }
        .title {

        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 40px;
            @media (max-width: $md6+px) {
                gap: 12px;
                margin-top: 20px;
            }
            li {
                position: relative;
                padding-left: 33px;
                @media (max-width: $md1+px) {
                    padding-left: 28px;
                }
                @media (max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../assets/img/HomePage/PlatformGlance/check.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @media (max-width: $md1+px) {
                        width: 16px;
                        height: 16px;
                        top: 3px;
                    }
                    @media (max-width: $md6+px) {
                        width: 14px;
                        height: 14px;
                        top: 2px;
                    }
                }
                b {
                    font-weight: 500;
                }
            }
        }
    }
    .image {
        position: relative;
        z-index: 2;
        width: 577px;
        @media (max-width: $md1+px) {
            width: 460px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            left: unset;
            top: unset;
        }
        @media (max-width: 650px) {
            width: 350px;
        }
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        height: 100%;
        @media (max-width: $md3+px) {
            height: 400px;
        }
        @media (max-width: $md6+px) {
            height: 340px;
        }
    }
}