@import '../../../styles/var';

.banner {
    position: relative;
    background: #0566EC;
    border-radius: 20px;
    margin-top: 100px;
    overflow: hidden;
    @media(max-width: $md1+px) {
        margin-top: 80px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
        border-radius: 10px;
    }
    .content {
        position: relative;
        z-index: 10;
        padding: 120px 80px 245px 80px;
        @media(max-width: $md1+px) {
            padding: 80px 80px 200px 80px;
        }
        @media(max-width: $md4+px) {
            padding: 60px 40px 0 40px;
        }
        @media(max-width: $md6+px) {
            padding: 20px 20px 0 20px;
        }
        .title {
            color: #FFFFFF;
            max-width: 700px;
        }
        .text {
            color: rgba(255, 255, 255, 0.8);
            margin: 40px 0 60px 0;
            max-width: 668px;
            @media(max-width: $md1+px) {
                margin: 20px 0 40px 0;
                max-width: 572px;
            }
            @media(max-width: $md4+px) {
                margin: 40px 0 60px 0;
            }
            @media(max-width: $md6+px) {
                margin: 20px 0 40px 0;
            }
        }
        .btn {

        }
    }
    .image_01 {
        position: absolute;
        z-index: 3;
        width: 680px;
        bottom: 0;
        right: 260px;
        @media(max-width: $md1+px) {
            width: 500px;
            right: 188px;
        }
        @media(max-width: $md2+px) {
            width: 450px;
            right: 140px;
        }
        @media(max-width: $md3+px) {
            width: 320px;
            right: 120px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            right: unset;
            bottom: unset;
            margin: 60px auto 0 auto;
            width: 440px;
            transform: translateX(-100px);
        }
        @media(max-width: 600px) {
            width: 240px;
            margin-top: 35px;
            transform: translateX(-65px);
        }
    }
    .image_02 {
        position: absolute;
        z-index: 2;
        width: 548px;
        bottom: 70px;
        right: 1px;
        @media(max-width: $md1+px) {
            width: 410px;
            right: 10px;
            bottom: 50px;
        }
        @media(max-width: $md2+px) {
            width: 340px;
            right: 1px;
        }
        @media(max-width: $md3+px) {
            width: 260px;
            bottom: 40px;
        }
        @media(max-width: $md4+px) {
            width: 380px;
            bottom: 60px;
            left: 0;
            right: -170px;
            margin: 0 auto;
        }
        @media(max-width: 600px) {
            width: 196px;
            bottom: 30px;
            right: -110px;
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 550px;
        @media(max-width: $md1+px) {
            width: 437px;
        }
        @media(max-width: $md2+px) {
            width: 350px;
        }
        @media(max-width: $md4+px) {
            transform: rotate(90deg);
            top: unset;
            bottom: 0;
        }
        @media(max-width: 600px) {
            width: 220px;
        }
    }
}