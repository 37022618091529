@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 100px;
    overflow: hidden;
    background: #0566EC;
    border-radius: 20px;
    padding: 80px 80px 56px 80px;
    @media(max-width: $md1+px) {
        margin-top: 80px;
        padding: 40px 80px;
    }
    @media(max-width: $md3+px) {
        padding: 60px 60px 60px 60px;
    }
    @media(max-width: $md4+px) {
        padding-bottom: 0;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
        border-radius: 10px;
        padding: 20px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            color: #FFFFFF;
            @media(max-width: $md6+px) {
                font-size: 32px;
            }
        }
        .subtitle {
            color: #FFFFFF;
            margin: 10px 0 40px 0;
            @media(max-width: $md4+px) {
                margin-top: 6px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 20px;
            }
        }
        .text {
            color: rgba(255, 255, 255, 0.8);
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 720px;
            @media(max-width: $md1+px) {
                max-width: 549px;
            }
        }
        .descTitle {
            color: #FFFFFF;
            margin: 80px 0 20px 0;
            @media(max-width: $md4+px) {
                display: none;
            }
        }
        .descText {
            color: #FFFFFF;
            font-weight: 400;
            @media(max-width: $md4+px) {
                display: none;
            }
        }
    }
    .woman {
        position: absolute;
        z-index: 2;
        right: -218px;
        bottom: 0;
        width: 990px;
        @media(max-width: $md1+px) {
            width: 764px;
            right: -167px;
        }
        @media(max-width: $md2+px) {
            width: 700px;
            right: -180px;
        }
        @media(max-width: $md3+px) {
            width: 450px;
            right: -100px;
        }
        @media(max-width: $md4+px) {
            width: 678px;
            position: relative;
            display: flex;
            right: unset;
            bottom: unset;
            margin: 50px auto 0 auto;
            transform: translateX(100px);
        }
        @media(max-width: $md5+px) {
            transform: translateX(50px);
            width: 500px;
        }
        @media(max-width: 600px) {
            transform: translateX(0);
        }
        @media(max-width: $md6+px) {
            width: 400px;
            margin-top: 40px;
            transform: translateX(-20px);
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 550px;
        @media(max-width: $md1+px) {
            width: 450px;
        }
        @media(max-width: $md2+px) {
            width: 400px;
        }
        @media(max-width: $md3+px) {
            width: 300px;
        }
        @media(max-width: $md4+px) {
            transform: rotate(180deg);
            top: unset;
            right: unset;
            left: 0;
            bottom: 0;
            width: 400px;
        }
        @media(max-width: $md5+px) {
            width: 350px;
            bottom: -50px;
            left: -50px;
        }
        @media(max-width: $md6+px) {
            width: 250px;
            left: -20px;
            bottom: -20px;
        }
    }
}

.mobDesc {
    display: none;
    @media(max-width: $md4+px) {
        display: block;
    }
    margin-top: 80px;
    .descTitle {
        color: #0566EC;
    }
    .descText {
        margin-top: 20px;
        font-weight: 400;
        color: #000000;
    }
}