@import "../../../styles/var";

.footer {
    position: relative;
    z-index: 10;
    color: rgba(0, 0, 0, 0.7);
    font-family: $roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
    padding-bottom: 30px;
    @media(max-width: $md1+px) {
        font-size: 14px;
        line-height: 16px;
    }
    .grid {
        display: grid;
        grid-template-columns: 110px 1fr 1fr;
        gap: 40px 100px;
        @media(max-width: $md1+px) {
            gap: 40px 60px;
        }
        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
        .logo {
            width: 110px;
            @media(max-width: $md3+px) {
                width: 100px;
            }   
            img {
                width: 100%;
            }
        }
        .contacts {
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
        .docs {
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
        .contacts, .docs {
            display: flex;
            flex-direction: column;
            gap: 15px;
            b {
                font-weight: 500;
            }
            a {
                text-decoration: none;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .disclosures_01, .disclosures_02 {
            font-family: $roboto;
            font-weight: 300;
            font-size: 12px;
            line-height: 130%;
            color: rgba(0, 0, 0, 0.7);
            .title {
                font-weight: 400;
            }
            @media(max-width: $md1+px) {
                font-size: 10px;
            }
        }
        .disclosures_01 {
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
            .text {
                margin-top: 12px;
            }
        }
        .disclosures_02 {
            padding-top: 24px;
            @media(max-width: $md1+px) {
                padding-top: 20px;
            }
            @media(max-width: $md4+px) {
                padding-top: 0;
            }
        }
    }
    .rights {
        margin-top: 40px;
        width: 100%;
        text-align: right;
        @media(max-width: $md1+px) {
            margin-top: 20px;
        }
        @media(max-width: $md4+px) {
            text-align: left;
        }
    }
}