@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 60px;
    overflow: hidden;
    padding: 196px 80px 196px 80px;
    @media(max-width: $md1+px) {
        padding: 120px 80px 120px 80px;
    }
    @media(max-width: $md3+px) {
        padding: 100px 80px 60px 80px;
    }
    @media(max-width: $md4+px) {
        padding: 40px 40px 50px 40px;
    }
    @media(max-width: $md6+px) {
        padding: 40px 20px 30px 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 774px;
        margin-left: auto;
        @media(max-width: $md1+px) {
            max-width: 666px;
        }
        @media(max-width: $md3+px) {
            max-width: 100%;
        }   
        .title {

        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 40px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 2;
        width: 555px;
        left: 80px;
        top: 70px;
        @media(max-width: $md1+px) {
            width: 400px;
            top: 50px;
            left: 60px;
        }
        @media(max-width: $md2+px) {
            width: 360px;
            top: 70px;
            left: 40px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            top: unset;
            left: unset;
            margin: 40px auto 0 auto;
        }
        @media(max-width: $md4+px) {
            width: 351px;
        }
        @media(max-width: $md6+px) {
            width: 290px;
        }
    }
}