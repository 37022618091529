@import "./_var.scss";

body,
html {
    background: #EFF0EF;
    color: rgba(0, 0, 0, 0.7);
    &.lock {
        overflow: hidden;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.section-bg {
    background: #FFFFFF;
    border-radius: 20px;
}

.font-64 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 140%;
    color: #0566EC;
    @media (max-width: $md1+px) {
        font-size: 48px;
    }
    @media (max-width: $md4+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-54 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    line-height: 140%;
    color: #0566EC;
    @media (max-width: $md1+px) {
        font-size: 42px;
    }
    @media (max-width: $md4+px) {
        font-size: 34px;
    }
    @media (max-width: $md6+px) {
        font-size: 23px;
    }
}

.font-48-banner {
    font-family: $manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #0566EC;
    @media (max-width: $md1+px) {
        font-size: 40px;
    }
    @media (max-width: $md4+px) {
        font-size: 36px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-48 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #0566EC;
    @media (max-width: $md1+px) {
        font-size: 40px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.subtitle-banner {
    font-family: $manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
    color: #0566EC;
    @media (max-width: $md1+px) {
        font-size: 32px;
    }
    @media (max-width: $md4+px) {
        font-size: 26px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-40 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    @media (max-width: $md1+px) {
        font-size: 36px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-32 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    @media (max-width: $md1+px) {
        font-size: 24px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-36 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 140%;
    @media (max-width: $md1+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-24 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    @media (max-width: $md1+px) {
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    font-family: $roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
    @media (max-width: $md1+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 180px;
    @media (max-width: $md1+px) {
        margin-top: 160px;
    }
    @media (max-width: $md4+px) {
        margin-top: 140px;
    }
    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.mb {
    margin-bottom: 180px;
    @media (max-width: $md1+px) {
        margin-bottom: 160px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 140px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}

.ovf-hidden {
    position: relative;
    overflow: hidden;
}

.Dropdown-control {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: #002350;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    max-height: 300px;
}
.Dropdown-arrow {
    border-color: #002350 transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #002350;
}
