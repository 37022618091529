.loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 1;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.spinner {
  width: 70px;
  height: 35px;
  position: relative;
  overflow: hidden;
}
.spinner:before {
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;
  border: 5px solid #0E80E1;
  border-color: #0E80E1 #0E80E1 #fff #fff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotate 3s ease-in-out infinite;
  transform: rotate(-200deg);
}
@keyframes rotate {
  0% {
    border-width: 10px;
  }
  25% {
    border-width: 3px;
  }
  50% {
    transform: rotate(115deg);
    border-width: 10px;
  }
  75% {
    border-width: 3px;
  }
  100% {
    border-width: 10px;
  }
}
    
