@import "../../../styles/var";

.what {
}
.whatBody {
}
.whatTitle {
    text-align: center;
    margin-bottom: 40px;
}
.whatRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0 20px;
    }
}
.whatRowColumn {
    text-align: center;
    background: #ffffff;
    border-radius: 20px;
    padding: 30px 0;
    flex: 0 1 308px;
    @media (max-width: $md1+px) {
        flex: 0 1 250px;
        padding: 20px 0;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 230px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 308px;
        display: flex;
        align-items: center;
        padding: 20px 30px;
        margin-bottom: 20px !important;
        &:last-child {
            // margin-bottom: 0 !important;
        }
    }
    @media (max-width: $md6+px) {
        flex: 0 1 100%;
    }
    top: 0;
    position: relative;
    transition: all 0.3s linear;
    &:hover {
        cursor: pointer;
        top: -10px;
    }
}
.whatRowColumnImg {
    margin-bottom: 20px;
    @media (max-width: $md3+px) {
        margin-right: 30px;
        margin-bottom: 0;
    }
}
.whatRowColumnText {
}
