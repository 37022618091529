@import '../../../styles/var';

.commissions {
    position: relative;
    .title {

    }
    .tabs { 
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media(max-width: $md4+px) {
            margin-top: 40px;
        }
        .tab { 
            position: relative;
            padding: 25px 100px;
            background: #FFFFFF;
            border-radius: 20px;
            @media(max-width: $md1+px) {
                padding: 20px 80px;
            }
            @media(max-width: $md3+px) {
                padding: 20px 40px;
            }
            @media(max-width: $md6+px) {
                padding: 13px 20px;
                border-radius: 10px;
            }
            &Active {
                .tabContent {
                    display: block;
                }
                .tabStatus {
                    &::before {
                        transform: rotate(90deg);
                    }
                }
            }
            &Header {
                cursor: pointer;
                display: grid;
                grid-template-columns: 1fr 40px;
                gap: 50px;
                align-items: center;
                @media(max-width: $md1+px) {
                    grid-template-columns: 1fr 30px;
                }
                .tabTitle {
                    font-family: $manrope;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 150%;
                    color: #0566EC;
                    @media(max-width: $md1+px) {
                        font-size: 24px;
                    }
                    @media(max-width: $md4+px) {
                        font-size: 20px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 16px;
                    }
                }
                .tabStatus {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    border: 1px solid #0566EC;
                    border-radius: 100%;
                    @media(max-width: $md1+px) {
                        width: 30px;
                        height: 30px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 20px;
                        top: calc(50% - 10px);
                        left: calc(50% - 1px);
                        background: #0566EC;
                        transition: all .2s;
                        @media(max-width: $md1+px) {
                            height: 14px;
                            top: calc(50% - 7px);
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 20px;
                        left: calc(50% - 10px);
                        top: calc(50% - 1px);
                        background: #0566EC;
                        @media(max-width: $md1+px) {
                            width: 14px;
                            left: calc(50% - 7px);
                        }
                    }
                }
            }
            &Content {
                display: none;
                margin-top: 40px;
                margin-bottom: 35px;
                @media(max-width: $md1+px) {
                    margin-bottom: 15px;
                }
                @media(max-width: $md4+px) {
                    margin-top: 30px;
                }
                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
                .tableWrap {
                    table {
                        width: 100%;
                        border-spacing: 0;
                        @media(max-width: $md4+px) {
                            font-size: 14px;
                        }
                        @media(max-width: $md6+px) {
                            font-size: 12px;
                        }
                        tr {
                            &:nth-child(odd) {
                                td {
                                    background: #F8FAFB;
                                    height: 48px;
                                    @media(max-width: $md1+px) {
                                        height: 40px;
                                    }
                                    @media(max-width: $md4+px) {
                                        height: 35px;
                                    }
                                }
                            }
                            &:nth-child(even) {
                                td {
                                    background: transparent;
                                    height: 70px;
                                    @media(max-width: $md1+px) {
                                        height: 48px;
                                    }
                                    @media(max-width: $md4+px) {
                                        height: 39px;
                                    }
                                }
                            }
                            td {
                                padding-right: 10px;
                                @media(max-width: 700px) {
                                    height: unset;
                                    padding-top: 6px;
                                    padding-bottom: 6px;
                                }
                                &:nth-child(1) {
                                    font-weight: 400;
                                    border-radius: 20px 0 0 20px;
                                    padding-left: 30px;
                                    @media(max-width: $md3+px) {
                                        padding-left: 20px;
                                        padding-right: 30px;
                                    }   
                                    @media(max-width: $md6+px) {
                                        padding-left: 10px;
                                    }
                                }
                                &:nth-last-child(1) {
                                    border-radius: 0 20px 20px 0;
                                    padding-right: 30px;
                                    @media(max-width: $md3+px) {
                                        padding-right: 20px;
                                    }   
                                    @media(max-width: $md6+px) {
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }
                    }   
                }
            }
            &:nth-child(2) {
                .tabContent {
                    @media(max-width: 900px) {
                        overflow: scroll;
                        overflow-y: hidden;
                    }
                }
                .tableWrap {
                    @media(max-width: 900px) {
                        width: 900px;
                    }
                    @media(max-width: $md6+px) {
                        width: 600px;
                    }
                }
                tr {
                    &:nth-child(1) {
                        td {
                            font-weight: 500!important;
                        }
                    }
                    &:nth-child(odd) {
                        td {
                            background: transparent!important;
                            height: 70px!important;
                            @media(max-width: $md1+px) {
                                height: 48px!important;
                            }
                            @media(max-width: $md4+px) {
                                height: 39px!important;
                            }
                        }
                    }
                    &:nth-child(even) {
                        td {
                            background: #F8FAFB!important;
                            height: 48px!important;
                            @media(max-width: $md1+px) {
                                height: 40px!important;
                            }
                            @media(max-width: $md4+px) {
                                height: 35px!important;
                            }
                        }
                    }
                }
            }
        }
    }
}