@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 100px;
    overflow: hidden;
    padding: 120px 80px 125px 80px;
    @media(max-width: $md1+px) {
        margin-top: 80px;
        padding: 100px 80px 100px 80px;
    }
    @media(max-width: $md3+px) {
        padding-bottom: 60px;
    }
    @media(max-width: $md4+px) {
        padding: 40px 40px 40px 40px;
    }
    @media(max-width: $md6+px) {
        margin-top: 90px;
        padding: 40px 20px 30px 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 774px;
        @media(max-width: $md1+px) {
            max-width: 666px;
        }
        @media(max-width: $md3+px) {
            max-width: 100%;
        }   
        .title {

        }
        .subtitle {
            margin: 10px 0 40px 0;
            font-weight: 600;
            color: #0566EC;
            @media(max-width: $md4+px) {
                margin-top: 6px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 20px;
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
    .image {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 20px;
        width: 680px;
        @media(max-width: $md1+px) {
            width: 460px;
            top: 60px; 
            right: 30px;
        }
        @media(max-width: $md2+px) {
            width: 420px;
            right: 10px;
            top: 80px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            right: unset;
            top: unset;
            width: 400px;
        }
        @media(max-width: 600px) {
            width: 350px;
        }
        @media(max-width: $md6+px) {
            width: 253px;
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 570px;
        @media(max-width: $md1+px) {
            width: 450px;
        }
        @media(max-width: $md2+px) {
            width: 400px;
        }
        @media(max-width: $md3+px) {
            transform: rotate(90deg);
            top: unset;
            bottom: 0;
        }
        @media(max-width: $md4+px) {
            width: 320px;
        }
        @media(max-width: $md6+px) {
            width: 240px;
        }
    }
}