@import '../../../styles/var';

.underBanner {
    position: relative;
    overflow: hidden;
    margin-top: 60px;
    padding: 140px 137px 200px 137px;
    @media (max-width: $md1+px) {
        padding: 120px 115px 160px 115px;
    }
    @media (max-width: $md2+px) {
        padding: 100px 80px 120px 80px;
    }
    @media (max-width: $md3+px) {
        padding-bottom: 0;
    }
    @media (max-width: $md4+px) {
        padding: 80px 50px 0 50px;
    }
    @media (max-width: $md6+px) {
        padding: 40px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 800px;
        @media (max-width: $md1+px) {
            max-width: 552px;
        }
        @media (max-width: $md3+px) {
            max-width: 100%;
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 40px;
            @media (max-width: $md1+px) {
                gap: 15px;
            }
            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 50px;
        top: 0;
        width: 592px;
        @media (max-width: $md1+px) {
            width: 480px;
        }
        @media (max-width: $md2+px) {
            width: 440px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0 auto;
            right: unset;
            bottom: unset;
            width: 562px;
        }
        @media (max-width: 800px) {
            width: 400px;
        }
        @media (max-width: 600px) {
            width: 340px;
        }
        @media (max-width: $md6+px) {
            margin-top: 30px;
            width: 100%;
        }
    }
}