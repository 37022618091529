@import "../../../styles/var";

.holding {
}
.holdingBody {
}
.holdingRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.holdingRowLeft {
    flex: 0 1 49%;
    position: relative;
    background: #0566ec;
    border-radius: 20px;
    padding: 80px;
    overflow: hidden;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 60px;
    }
    @media (max-width: $md5+px) {
        padding: 60px 60px 277px 60px !important;
    }
    @media (max-width: $md6+px) {
        padding: 40px 20px 240px 20px !important;
    }
}
.holdingRowLeftAbs {
    position: absolute;
    right: 30px;
    bottom: 0;
    width: 658px;
    height: 406px;
    @media (max-width: $md1+px) {
        width: 558px;
        height: 306px;
        right: -20px;
    }
    @media (max-width: $md5+px) {
        right: -54px;
    }
    @media (max-width: 600px) {
        width: 458px;
        height: 256px;
    }
    @media (max-width: $md6+px) {
        width: 405px;
        height: 215px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.holdingRowLeftTitle {
    color: #fff !important;
    position: relative;
    z-index: 3;
    margin-bottom: 40px;
}
.holdingRowLeftList {
}
.holdingRowLeftListItem {
    color: rgba(255, 255, 255, 0.7) !important;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
        margin-right: 15px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.holdingRowRight {
    flex: 0 1 49%;
    background: #ffffff;
    border-radius: 20px;
    padding: 40px 20px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.holdingRowRightTitle {
    text-align: center;
    margin-bottom: 40px;
    color: #0566ec !important;
    font-weight: 500 !important;
}
.holdingRowRightImgs1 {
    display: flex;
    justify-content: space-between;
}
.holdingRowRightImgs1Left {
    flex: 0 1 49%;
    background: #f8fafb;
    border-radius: 20px;
    text-align: center;
    padding-bottom: 10px;
}
.holdingRowRightImgs1LeftImg {
    img {
    }
}
.holdingRowRightImgs1LeftText {
    color: #dd2f2f !important;
    span {
        margin-left: 5px;
        font-weight: 400 !important;
    }
}
.holdingRowRightImgs1Right {
    flex: 0 1 49%;
    text-align: center;
    background: #f8fafb;
    border-radius: 20px;
    padding-bottom: 10px;
}
.holdingRowRightImgs1RightImg {
}
.holdingRowRightImgs1RightText {
    color: #1b993d !important;
    span {
        margin-left: 5px;
        font-weight: 400 !important;
    }
}
.holdingRowRightImgs2 {
    display: flex;
    position: relative;
    justify-content: space-between;
    @media (max-width: 650px) {
        margin-bottom: 30px;
        padding-top: 20px;
    }
}
.holdingRowRightImgs2Left {
    flex: 0 1 49%;
    text-align: center;
}
.holdingRowRightImgs2Center {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @media (max-width: 650px) {
        position: relative;
    }
    margin: auto;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 10px;
        position: absolute;
        width: 40px;
        height: 2px;
        background: #0566ec;
    }
    &:before {
        content: "";
        display: inline-block;
        left: 20px;
        top: -10px;
        position: absolute;
        width: 2px;
        height: 40px;
        background: #0566ec;
    }
}
.holdingRowRightImgs2Right {
    flex: 0 1 49%;
    text-align: center;
}
.holdingRowRightBottom {
    display: flex;
    background: #f8fafb;
    border-radius: 20px;
    padding: 18px 70px;
    margin-bottom: 20px;
    @media (max-width: $md2+px) {
        padding: 18px 30px;
    }
}
.holdingRowRightBottomLeft {
    flex: 0 1 50%;
    text-align: center;
    @media (max-width: 650px) {
        background: #f8fafb;
        border-radius: 20px;
        padding: 20px 0;
        margin-bottom: 30px;
    }
}
.holdingRowRightBottomLeftTitle {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}
.holdingRowRightBottomLeftText {
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}
.holdingRowRightBottomRight {
    flex: 0 1 50%;
    text-align: center;
    @media (max-width: 650px) {
        background: #f8fafb;
        border-radius: 20px;
        padding: 20px 0;
        margin-bottom: 30px;
    }
}
.holdingRowRightBottomRightTitle {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}
.holdingRowRightBottomRightText {
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.7) !important;
}
.holdingRowRightTotal {
    color: #0566ec !important;
    text-align: center;
    font-weight: 400 !important;
}

.desk {
    @media (max-width: 650px) {
        display: none;
    }
}
.mob {
    display: none;
    @media (max-width: 650px) {
        display: block;
    }
}
