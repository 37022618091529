@import "../../../styles/var";

.hours {
}
.hoursBody {
}
.hoursTitle {
    margin-bottom: 40px;
}
.hoursSubtitle {
    max-width: 800px;
    margin-bottom: 60px;
}
.hoursTabs {
}
.hoursTab {
    background: #ffffff;
    border-radius: 20px;
    padding: 25px 100px;
    margin-bottom: 10px;
    @media (max-width: $md1+px) {
        padding: 20px 80px;
    }
    @media (max-width: $md3+px) {
        padding: 20px 40px;
    }
    @media (max-width: $md6+px) {
        padding: 13px 20px;
        border-radius: 10px;
    }
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
}
.hoursTabTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hoursTabTopLeft {
    color: #0566ec !important;
    font-weight: 400 !important;
}
.hoursTabTopRight {
    &.active {
        .hoursTabTopRightCircle {
            span {
                &:nth-child(2) {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
.hoursTabTopRightCircle {
    width: 40px;
    height: 40px;
    border: 1px solid #0566ec;
    border-radius: 100px;
    position: relative;
    @media (max-width: $md1+px) {
        height: 30px;
        width: 30px;
    }
    span {
        &:nth-child(1) {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 2px;
            display: block;
            width: 20px;
            background-color: #0566ec;
            @media (max-width: $md1+px) {
                width: 14px;
            }
        }
        &:nth-child(2) {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 20px;
            display: block;
            width: 2px;
            transition: all 0.3s linear;
            background-color: #0566ec;
            @media (max-width: $md1+px) {
                height: 14px;
            }
        }
    }
}
.hoursTabContent {
}

.forex {
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 860px) {
        overflow: auto;
    }
}
.forexTable {
    // max-width: 832px;
    @media (max-width: 770px) {
        width: 610px;
    }
    @media (max-width: $md6+px) {
        width: 530px;
    }

    margin: 0 auto;
}
.forexTop {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.forexTopItem {
    font-weight: 400 !important;
    &:nth-child(1) {
        flex: 0 1 290px;
        @media (max-width: $md1+px) {
            flex: 0 1 200px;
        }
        @media (max-width: $md3+px) {
            flex: 0 1 150px;
        }
        @media (max-width: 860px) {
            flex: 0 1 120px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 90px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 387px;
        @media (max-width: $md1+px) {
            flex: 0 1 300px;
        }
        @media (max-width: $md3+px) {
            flex: 0 1 250px;
        }
        @media (max-width: 860px) {
            flex: 0 1 230px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 190px;
        }
    }
    &:nth-child(3) {
        flex: 0 1 130px;
        @media (max-width: 860px) {
            flex: 0 1 100px;
        }
    }
}
.forexRow {
    display: flex;
    justify-content: center;
    padding: 9px 0;
    &:nth-child(even) {
        background: #f8fafb;
        border-radius: 20px;
    }
    &:nth-child(3) {
        .forexRowItem {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: -50px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 34px;
                    height: 20px;
                    background: url("../../../assets/img/trade/3/firstTabs/11.png");
                }
            }
        }
    }
    &:nth-child(4) {
        .forexRowItem {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: -50px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 34px;
                    height: 20px;
                    background: url("../../../assets/img/trade/3/firstTabs/12.png");
                }
            }
        }
    }
}
.forexRowItem {
    &:nth-child(1) {
        flex: 0 1 290px;
        @media (max-width: $md1+px) {
            flex: 0 1 200px;
        }
        @media (max-width: $md3+px) {
            flex: 0 1 150px;
        }
        @media (max-width: 860px) {
            flex: 0 1 120px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 90px;
        }
        position: relative;
    }
    &:nth-child(2) {
        flex: 0 1 387px;
        @media (max-width: $md1+px) {
            flex: 0 1 300px;
        }
        @media (max-width: $md3+px) {
            flex: 0 1 250px;
        }
        @media (max-width: 860px) {
            flex: 0 1 230px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 190px;
        }
    }
    &:nth-child(3) {
        flex: 0 1 130px;
        @media (max-width: 860px) {
            flex: 0 1 100px;
        }
    }
}

.stocks {
    margin-top: 40px;
    margin-bottom: 40px;
    overflow: auto;
}
.stocksTable {
    @media (max-width: $md4+px) {
        width: 787px;
    }
    @media (max-width: $md5+px) {
        width: 737px;
    }
    @media (max-width: $md6+px) {
        width: 580px;
    }
}
.stocksTableTop {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.stocksTableTopItem {
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.7) !important;
    &:nth-child(1) {
        flex: 0 1 458px;
        padding-left: 48px;
        @media (max-width: $md2+px) {
            flex: 0 1 358px;
        }
        @media (max-width: $md5+px) {
            flex: 0 1 328px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 280px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 350px;
        @media (max-width: $md6+px) {
            flex: 0 1 250px;
        }
    }
}
.stocksTableRow {
    display: flex;
    justify-content: center;
    padding: 9px 0;
    &:nth-child(even) {
        background: #f8fafb;
        border-radius: 20px;
    }
    &:nth-child(2) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/21.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(3) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/22.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(4) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/23.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(5) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/24.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(6) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/25.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(7) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/26.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
    &:nth-child(8) {
        .stocksTableRowColumn {
            &:nth-child(1) {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: url("../../../assets/img/trade/3/firstTabs/27.png") 0 0 / contain no-repeat;
                }
            }
        }
    }
}
.stocksTableRowColumn {
    &:nth-child(1) {
        flex: 0 1 458px;
        padding-left: 48px;
        @media (max-width: $md2+px) {
            flex: 0 1 358px;
        }
        @media (max-width: $md5+px) {
            flex: 0 1 328px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 280px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 350px;
        @media (max-width: $md6+px) {
            flex: 0 1 250px;
        }
    }
}

.bonds {
    margin: 40px 0;
    @media (max-width: 850px) {
        overflow: auto;
    }
}
.bondsTable {
    @media (max-width: 850px) {
        width: 682px;
    }
    @media (max-width: $md6+px) {
        width: 530px;
    }
}
.bondsTableTop {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.bondsTableTopItem {
    font-weight: 400 !important;
    &:nth-child(1) {
        flex: 0 1 410px;
        @media (max-width: $md2+px) {
            flex: 0 1 310px;
        }
        @media (max-width: 900px) {
            flex: 0 1 280px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 240px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 340px;
        @media (max-width: $md6+px) {
            flex: 0 1 240px;
        }
    }
}
.bondsTableRow {
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    padding: 9px 0;
    &:nth-child(even) {
        background: #f8fafb;
        border-radius: 20px;
    }
}
.bondsTableRowColumn {
    &:nth-child(1) {
        flex: 0 1 410px;
        @media (max-width: $md2+px) {
            flex: 0 1 310px;
        }
        @media (max-width: 900px) {
            flex: 0 1 280px;
        }
        @media (max-width: $md6+px) {
            flex: 0 1 240px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 340px;
        @media (max-width: $md6+px) {
            flex: 0 1 240px;
        }
    }
}

.efts {
    .bondsTable {
        @media (max-width: 850px) {
            width: 640px;
        }
        @media (max-width: $md6+px) {
            width: 460px;
        }
    }
    .bondsTableTopItem {
        font-weight: 400 !important;
        &:nth-child(1) {
            flex: 0 1 410px;
            @media (max-width: $md2+px) {
                flex: 0 1 310px;
            }
            @media (max-width: 900px) {
                flex: 0 1 200px;
            }
            @media (max-width: $md6+px) {
                flex: 0 1 160px;
            }
        }
        &:nth-child(2) {
            flex: 0 1 340px;
            @media (max-width: $md6+px) {
                flex: 0 1 240px;
            }
        }
    }
    .bondsTableRowColumn {
        &:nth-child(1) {
            flex: 0 1 410px;
            @media (max-width: $md2+px) {
                flex: 0 1 310px;
            }
            @media (max-width: 900px) {
                flex: 0 1 200px;
            }
            @media (max-width: $md6+px) {
                flex: 0 1 160px;
            }
        }
        &:nth-child(2) {
            flex: 0 1 340px;
            @media (max-width: $md6+px) {
                flex: 0 1 240px;
            }
        }
    }
}

.commodities {
    .forexRow {
        &:nth-child(2) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/51.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(3) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/52.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(4) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/53.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(5) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/54.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(6) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/55.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(7) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/56.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(8) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/57.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(9) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;
                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/58.png") 0 0 / contain no-repeat;
                    }
                }
            }
        }
        &:nth-child(10) {
            .forexRowItem {
                &:nth-child(1) {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        left: -50px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        position: absolute;
                        width: 34px;

                        height: 20px;
                        background: url("../../../assets/img/trade/3/firstTabs/59.png") center 0 / contain no-repeat;
                    }
                }
            }
        }
    }
}
