@import '../../../styles/var';
@import '../../../styles/mixins';

.bannerWrap {
    position: relative;
    .mapLeft__container {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 527px;
        @media(max-width: $md1+px) {
            width: 400px;
        }
        @media(max-width: $md2+px) {
            width: 350px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
        .mapLeft__wrap {
            width: 100%;
            .mapLeft {
                width: 100%;
            }
        }
    }
    .mapRight__container {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 624px;
        @media(max-width: $md1+px) {
            width: 487px;
        }
        @media(max-width: $md2+px) {
            width: 420px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
        .mapRight__wrap {
            width: 100%;
            .mapRight {
                width: 100%;
            }
        }
    }
    .bgMob {
        display: none;
        @media(max-width: $md4+px) {
            display: flex;
            position: absolute;
            z-index: 1;
            left: calc(50% - 990px / 2);
            width: 990px;
            top: -50px;
        }
        @media(max-width: 900px) {
            left: calc(50% - 910px / 2);
            width: 910px;
            top: -10px;
        }
        @media(max-width: 800px) {
            left: calc(50% - 810px / 2);
            width: 810px;
            top: 40px;
        }
        @media(max-width: $md6+px) {
            left: calc(50% - 582px / 2);
            width: 582px;
            top: 30px;
        }
    }
}

.banner {
    position: relative;
    height: 100%;
    .planeContainer {
        position: absolute;
        z-index: 3;
        @media(max-width: $md4+px) {
            display: none;
        }
        .planeWrap {
            width: 100%;
            .plane {
                width: 100%;
            }
        }
        &.plane_01__container {
            width: 331px;
            left: 63px;
            bottom: 137px;
            @media(max-width: $md1+px) {
                bottom: 63px;
                left: 75px;
                width: 280px;
            }
            @media(max-width: $md2+px) {
                width: 220px;
                bottom: 20px;
            }
            @media(max-width: $md3+px) {
                width: 200px;
                left: 40px;
            }
        }
        &.plane_02__container {
            width: 319px;
            top: 4px;
            right: 270px;
            @media(max-width: $md1+px) {
                right: 150px;
                width: 240px;
                top: 0;
            }
            @media(max-width: $md2+px) {
                width: 200px;
                top: 10px;
            }
            @media(max-width: $md3+px) {
                top: 30px;
                width: 180px;
            }
        }
        &.plane_03__container {
            width: 270px;
            bottom: 40px;
            right: 160px;
            @media(max-width: $md1+px) {
                bottom: 100px;
                right: 260px;
                width: 230px;
            }
            @media(max-width: $md2+px) {
                width: 200px;
                bottom: 40px;
                right: 230px;
            }
            @media(max-width: $md3+px) {
                width: 170px;
                right: 100px;
            }
        }
    }
    .coinContainer {
        position: absolute;
        z-index: 40;
        @media(max-width: $md4+px) {
            display: none;
        }
        .coinWrap {
            width: 100%;
            @media(max-width: $md1+px) {
                transform: scale(0.8);
            }
            @media(max-width: $md2+px) {
                transform: scale(0.7);
            }
            @media(max-width: $md3+px) {
                transform: scale(0.6);
            }
            .coin {
                width: 100%;
            }
        }
        &.coin_01 {
            width: 138px;
            top: 164px;
            left: 290px;
            img {
                @include animation("levitating-3 ease-in-out 4s alternate infinite");
            }
            @media(max-width: $md1+px) {
                top: 80px;
                left: 170px;
            }
            @media(max-width: $md2+px) {
                left: 120px;
            }
            @media(max-width: $md3+px) {
                left: 50px;
                top: 70px;
            }
        }
        &.coin_02 {
            width: 150px;
            top: 112px;
            left: 440px;
            img {
                @include animation("levitating-2 ease-in-out 3s alternate infinite");
            }
            @media(max-width: $md1+px) {
                top: 30px;
                left: 300px;
            }
            @media(max-width: $md2+px) {
                left: 250px;
            }
            @media(max-width: $md3+px) {
                left: 180px;
            }
        }
        &.coin_03 {
            width: 181px;
            bottom: 148px;
            left: 350px;
            img {
                @include animation("levitating-4 ease-in-out 3s alternate infinite");
            }
            @media(max-width: $md1+px) {
                bottom: 60px;
                left: 300px;
            }
            @media(max-width: $md2+px) {
                bottom: 20px;
                left: 240px;
            }
            @media(max-width: $md3+px) {
                bottom: 0;
                left: 200px;
            }
        }
        &.coin_04 {
            width: 181px;
            bottom: 200px;
            left: 440px;
            img {
                @include animation("levitating-3 ease-in-out 4s alternate infinite");
            }
            @media(max-width: $md1+px) {
                left: 380px;
                bottom: 120px;
            }
            @media(max-width: $md2+px) {
                left: 310px;
                bottom: 70px;
            }
            @media(max-width: $md3+px) {
                bottom: 50px;
                left: 280px;
            }
        }
        &.coin_05 {
            width: 119px;
            top: 270px;
            right: 290px;
            img {
                @include animation("levitating-4 ease-in-out 4s alternate infinite");
            }
            @media(max-width: $md1+px) {
                top: 170px;
                right: 160px;
            }
            @media(max-width: $md2+px) {
                right: 120px;
                top: 150px;
            }
            @media(max-width: $md3+px) {
                right: 100px;
                top: 130px;
            }
        }
        &.coin_06 {
            width: 189px;
            bottom: 260px;
            right: 220px;
            img {
                @include animation("levitating-2 ease-in-out 3s alternate infinite");
            }
            @media(max-width: $md1+px) {
                bottom: 170px;
                right: 140px;
            }
            @media(max-width: $md2+px) {
                bottom: 100px;
                right: 110px;
            }
            @media(max-width: $md3+px) {
                bottom: 20px;
                right: 220px;
            }
        }
        &.coin_07 {
            width: 181px;
            right: 90px;
            bottom: 320px;
            img {
                @include animation("levitating-4 ease-in-out 4s alternate infinite");
            }
            @media(max-width: $md1+px) {
                right: 50px;
                bottom: 240px;
            }
            @media(max-width: $md2+px) {
                bottom: 170px;
                right: 30px;
            }
            @media(max-width: $md3+px) {
                right: 0;
                bottom: 160px;
            }
        }
    }
    .content {
        position: relative;
        z-index: 100;
        padding-top: 310px;
        text-align: center;
        padding-bottom: 370px;
        @media(max-width: $md1+px) {
            padding-top: 180px;
            padding-bottom: 320px;
        }
        @media(max-width: $md2+px) {
            padding-bottom: 220px;
        }
        @media(max-width: $md4+px) {
            padding-top: 310px;
            padding-bottom: 350px;
        }
        @media(max-width: 800px) {
            padding-bottom: 300px;
        }
        @media(max-width: 660px) {
            padding-bottom: 250px;
        }
        @media(max-width: $md6+px) {
            padding-top: 166px;
            padding-bottom: 150px;
        }
        .title {
            display: flex;
            flex-direction: column;
            gap: 0;
            @media(max-width: $md6+px) {
                max-width: 290px;
                margin: 0 auto;
            }
        }
        .text {
            max-width: 761px;
            margin: 20px auto 40px auto;
            font-weight: 400;
            @media(max-width: $md1+px) {
                max-width: 628px;
            }
            @media(max-width: $md4+px) {
                max-width: 485px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }
        .btn {

        }
    }
}