@import '../../../styles/var';

.advantages {
    position: relative;
    .title {

    }
    .subtitle {
        color: #000000;
        margin: 60px 0 20px 0;
        @media (max-width: $md1+px) {
            margin: 40px 0 20px 0;
        }
        @media (max-width: $md6+px) {
            margin: 20px 0;
        }
    }
    .text {
        max-width: 800px;
        @media (max-width: $md1+px) {
            max-width: 686px;
        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top: 60px;
        @media (max-width: $md4+px) {
            margin-top: 40px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;
            gap: 50px;
        }
        .card {
            position: relative;
            border-radius: 10px;
            transition: background .3s;
            padding: 40px 30px 70px 40px;
            @media (max-width: $md2+px) {
                padding: 30px 20px 50px 30px;
            }
            @media (max-width: $md5+px) {
                padding: 0;
            }
            @media (min-width: $md5+px) {
                &:hover {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 24px rgba(207, 207, 207, 0.25);
                }
            }
            &Icon {
                width: 80px;
                @media (max-width: $md1+px) {
                    width: 60px;
                }
                @media (max-width: $md6+px) {
                    width: 45px;
                }
            }
            &Title {
                color: #000000;
                margin: 40px 0 20px 0;
                @media (max-width: $md4+px) {
                    margin: 30px 0 15px 0;
                }
                @media (max-width: $md6+px) {
                    margin: 20px 0 12px 0;
                }
            }
            &Text {

            }
        }
    }
}