@import "../../../styles/var";

.button {
    padding: 13px 30px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    border-radius: 40px;
    text-align: center;
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    transition: all 0.3s;
    @media(max-width: $md1+px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 16px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 200%;
        height: 100%;
        transform: translateX(-40%);
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #0566EC 0%, #A346FF 100%);
        transition: all .5s;
        border-radius: 40px;
    }
    &:hover {
        &::before {
            transform: translateX(0);
        }
    }
    span {
        position: relative;
        width: 100%;
        text-align: center;
    }
    &.secondary {
        background-color: #fff;
        border: 1px solid #fff;
        color: #0566EC;
        &::before {
            display: none;
        }
        &:hover {
            background: transparent;
            color: #fff;
            border-color: #fff;
        }
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
