@import "../../../styles/var";

.banner {
    margin: 100px 0 0 0;
}
.bannerBody {
}
.bannerWrapContent {
    position: relative;
    background: #0566ec;
    border-radius: 20px;
    padding: 100px 80px 120px 80px;
    @media (max-width: $md1+px) {
        padding: 80px 114px 150px 114px;
    }
    @media (max-width: $md2+px) {
        padding: 80px 80px 150px 80px;
    }
    @media (max-width: $md3+px) {
        padding: 60px 40px 346px 40px;
    }
    @media (max-width: $md5+px) {
        padding: 60px 40px 280px 40px;
    }
    @media (max-width: $md6+px) {
        padding: 20px 20px 280px 20px;
    }
}
.bannerTitle {
    position: relative;
    z-index: 2;
    color: #fff !important;
}
.bannerSubtitle {
    position: relative;
    z-index: 2;
    color: #fff !important;
    margin-bottom: 30px;
}
.bannerText {
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.8) !important;
    max-width: 774px;
    @media (max-width: $md1+px) {
        max-width: 500px;
    }
}
.bannerOverBtn {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #ffffff !important;
    max-width: 690px;
    font-weight: 500 !important;
    @media (max-width: $md1+px) {
        max-width: 500px;
    }
}
.bannerBtn {
}
.bannerImfgs {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerImfgsImg1 {
    position: absolute;
    right: 308px;
    top: 92px;
    width: 143px;
    z-index: 2;
    height: 161px;
    transform: translate(0, -80px);
    opacity: 0;
    @media (max-width: $md1+px) {
        width: 105px;
        height: 120px;
        top: 120px;
        right: 250px;
    }
    @media (max-width: $md2+px) {
        top: 240px;
        right: 300px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerImfgsImg2 {
    position: absolute;
    right: -70px;
    z-index: 2;
    top: 80px;
    width: 203.39px;
    height: 206.76px;
    transform: translate(100px, 0px);
    opacity: 0;
    @media (max-width: $md1+px) {
        width: 171.5px;
        height: 174.34px;
        right: -40px;
        top: 100px;
    }
    @media (max-width: $md2+px) {
        top: 160px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerImfgsImg3 {
    position: absolute;
    right: 500px;
    top: 570px;
    width: 129.24px;
    height: 75.48px;
    z-index: 2;
    transform: translate(0, -30px);
    opacity: 0;
    @media (max-width: $md1+px) {
        width: 108.51px;
        height: 63.37px;
        right: 420px;
        top: 510px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerImfgsImg4 {
    position: absolute;
    right: 570px;
    top: 660px;
    width: 187.62px;
    height: 252.95px;
    z-index: 2;
    transform: translate(0, 80px);
    opacity: 0;
    @media (max-width: $md1+px) {
        width: 151.42px;
        height: 204.14px;
        top: 635px;
        right: 430px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerImfgsImg5 {
    position: absolute;
    right: 20px;
    bottom: -60px;

    width: 470px;
    z-index: 2;
    height: 531px;
    transform: translate(0, 100px);

    @media (max-width: $md1+px) {
        width: 410px;
        height: 463px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerImfgsImg6 {
    position: absolute;
    right: -380px;
    top: 40px;
    width: 680px;
    height: 680px;
    transform: translate(150px, 0px);
    opacity: 0;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.bannerMob {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 636px;
    height: 406px;
    @media (max-width: $md5+px) {
        width: 536px;
        height: 306px;
    }
    @media (max-width: 530px) {
        width: 460px;
        height: 280px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        object-position: right bottom;
    }
}
