@import '../../../styles/var';

.accountTypes {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 100px 100px 50px 100px;
    @media(max-width: $md1+px) {
        padding: 40px 90px 50px 90px;
    }
    @media(max-width: $md3+px) {
        padding: 40px 40px 50px 40px;
    }
    @media(max-width: 600px) {
        padding: 40px 20px 30px 20px;
    }
    .coin {
        position: absolute;
        z-index: 1;
        @media(max-width: $md3+px) {
            display: none;
        }
        &_01 {
            top: 0;
            left: 60px;
            width: 304px;
            @media(max-width: $md1+px) {
                width: 220px;
                left: 40px;
            }
            @media(max-width: $md2+px) {
                width: 200px;
            }
        }
        &_02 {
            right: 0;
            top: 50px;
            width: 164px;
            @media(max-width: $md1+px) {
                width: 120px;
                top: 30px;
            }
            @media(max-width: $md2+px) {
                width: 110px;
            }
        }
        &_03 {
            bottom: 0;
            left: 430px;
            width: 234px;
            @media(max-width: $md1+px) {
                width: 200px;
                left: 350px;
            }
            @media(max-width: $md2+px) {
                width: 180px;
                left: 320px;
            }
        }
    }
    .title {
        position: relative;
        z-index: 10;
        text-align: center;
    }
    .tableWrap {
        position: relative;
        z-index: 10;
        margin-top: 60px;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr 1fr;
        gap: 0;
        @media(max-width: 1100px) {
            display: none;
        }
        .col {
            &:nth-child(1) {
                .row {
                    padding-left: 50px;
                    text-align: left;
                    justify-content: flex-start;
                    border-radius: 20px 0 0 20px;
                    font-weight: 400;
                    @media(max-width: $md2+px) {
                        padding-left: 30px;
                    }
                    &:nth-child(1) {
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }
            &:nth-last-child(1) {
                .row {
                    border-radius: 0 20px 20px 0;
                    padding-right: 50px;
                    @media(max-width: $md2+px) {
                        padding-right: 30px;
                    }
                }
            }
            .row {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 50px;
                @media(max-width: $md1+px) {
                    height: 40px;
                }
                &:nth-child(1) {
                    font-weight: 500;
                    color: #0566EC;
                }
                &:nth-child(even) {
                    background: #F8FAFB;
                }
            }
        }
    }
    .buttons {
        display: none;
        @media(max-width: 1100px) {
            display: grid;
        }
        grid-template-columns: repeat(4, 1fr);
        background: #FFFFFF;
        border: 1px solid #0566EC;
        border-radius: 40px;
        margin: 40px 0 40px 0;
        width: calc(100% + 80px);
        transform: translateX(-40px);
        @media(max-width: 700px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 10px;
            border: none;
            width: 100%;
            transform: none;
        }
        .button {
            position: relative;
            color: #0566EC;
            height: 50px;
            background: transparent;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: $roboto;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            cursor: pointer;
            @media(max-width: 700px) {
                border: 1px solid #0566EC;
            }
            @media(max-width: $md6+px) {
                font-size: 16px;
                height: 40px;
            }
            &Active {
                background: #0566EC;
                color: #fff;
            }
        }
    }
    .tableMob {
        display: none;
        @media(max-width: 1100px) {
            display: grid;
        }
        position: relative;
        grid-template-columns: repeat(2, 1fr);
        @media(max-width: $md6+px) {
            grid-template-columns: 1fr auto;
            width: calc(100% + 20px);
            transform: translateX(-10px);
        }
        .col {
            &:nth-child(1) {
                .row {
                    justify-content: flex-start;
                    text-align: left;
                }
            }
            &:nth-child(1) {
                .row {
                    padding-left: 60px;
                    border-radius: 20px 0 0 20px;
                    font-weight: 400;
                    @media(max-width: 750px) {
                        padding-left: 30px;
                    }
                    @media(max-width: 600px) {
                        padding-left: 10px;
                    }
                }
            }
            &:nth-last-child(1) {
                .row {
                    border-radius: 0 20px 20px 0;
                    padding-right: 60px;
                    @media(max-width: 750px) {
                        padding-right: 30px;
                    }
                    @media(max-width: 650px) {
                        padding-right: 10px;
                    }
                }
            }
            .row {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                @media(max-width: $md6+px) {
                    height: 32px;
                }
                &:nth-child(even) {
                    background: #F8FAFB;
                }
            }
        }
    }
    .btn {
        text-align: center;
        margin-top: 40px;
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
    }
}