@import "../../../styles/var";

.accountProtection {
    position: relative;
    padding: 60px 137px 66px 137px;
    overflow: hidden;
    margin-top: 60px;
    @media (max-width: $md1+px) {
        padding: 60px 114px 50px 114px;
    }
    @media (max-width: $md2+px) {
        padding: 50px 80px 50px 80px;
    }
    @media (max-width: $md3+px) {
        padding-bottom: 30px;
    }
    @media (max-width: $md4+px) {
        padding: 60px 50px 30px 50px;
    }
    @media (max-width: $md6+px) {
        padding: 40px 20px 20px 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        width: 725px;
        margin-left: auto;
        @media (max-width: $md1+px) {
            width: 666px;
        }
        @media (max-width: $md3+px) {
            width: 100%;
        }
        .title {
            span {
                font-weight: 600;
                @media (max-width: $md6+px) {
                    font-size: 28px;
                }
            }
        }
        .text {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 2;
        width: 500px;
        top: 0;
        left: 100px;
        @media (max-width: $md1+px) {
            width: 360px;
            left: 60px;
            top: 30px;
        }
        @media (max-width: $md2+px) {
            width: 340px;
            left: 40px;
            top: 50px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            width: 400px;
            top: unset;
            left: unset;
        }
        @media (max-width: 600px) {
            width: 220px;
        }
    }
}
