@import '../../../styles/var';

.fastConv {
    position: relative;
    .title {
        max-width: 750px;
        text-align: center;
        margin: 0 auto;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        justify-content: center;
        justify-items: center;
        width: 1200px;
        margin: 40px auto 0 auto;
        gap: 20px 0;
        @media(max-width: $md3+px) {
            width: 100%;
        }
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }
        @media(max-width: $md6+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 20px 30px;
        }
        .image { 
            height: 80px;
            &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                transform: translateX(150px);
                @media(max-width: $md3+px) {
                    transform: translateX(70%);
                }
                @media(max-width: $md4+px) {
                    transform: none;
                }
            }
            @media(max-width: $md4+px) {
                &:nth-last-child(1) {
                    grid-column-start: 2;
                    grid-column-end: 2;
                }
            }
            @media(max-width: 600px) {
                height: 70px;
            }
            @media(max-width: $md6+px) {
                height: 60px;
                &:nth-last-child(1) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
        }
    }
}