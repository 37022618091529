@import '../../../styles/var';

.platformGlance {
    position: relative;
    overflow: hidden;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding: 65px 76px 0 0;
    @media (max-width: $md1+px) {
        padding: 50px 50px 0 0;
    }
    @media (max-width: $md2+px) {
        padding: 40px 50px 0 0;
    }
    @media (max-width: $md3+px) {
        padding: 100px 80px 0 80px;
        justify-content: unset;
        align-items: unset;
        flex-direction: column-reverse;
    }
    @media (max-width: $md4+px) {
        padding: 80px 50px 0 50px;
    }
    @media (max-width: $md6+px) {
        padding: 40px 20px 0 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        width: 663px;
        padding-top: 100px;
        @media (max-width: $md1+px) {
            width: 552px;
            padding-top: 60px;
        }
        @media (max-width: $md3+px) {
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            padding-top: 0;
        }
        .title {

        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 40px;
            @media (max-width: $md6+px) {
                gap: 12px;
                margin-top: 20px;
            }
            li {
                position: relative;
                padding-left: 33px;
                @media (max-width: $md1+px) {
                    padding-left: 28px;
                }
                @media (max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../assets/img/HomePage/PlatformGlance/check.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @media (max-width: $md1+px) {
                        width: 16px;
                        height: 16px;
                        top: 3px;
                    }
                    @media (max-width: $md6+px) {
                        width: 14px;
                        height: 14px;
                        top: 2px;
                    }
                }
                b {
                    font-weight: 500;
                }
            }
        }
    }
    .image {
        position: relative;
        z-index: 2;
        width: 807px;
        @media (max-width: $md1+px) {
            width: 680px;
        }
        @media (max-width: $md2+px) {
            width: 580px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            left: unset;
            top: unset;
        }
        @media (max-width: 650px) {
            width: 350px;
        }
        @media (max-width: 480px) {
            width: calc(100% + 40px);
            transform: translateX(-20px);
        }
    }
    .circle {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        height: 100%;
        @media (max-width: $md3+px) {
            display: none;
        }
    }
}