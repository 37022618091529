@import '../../../styles/var';

.seeWhy {
    position: relative;
    .title {

    }
    .text { 
        max-width: 800px;
        margin: 40px 0 60px 0;
        @media(max-width: $md1+px) {
            max-width: 666px;
        }
        @media(max-width: $md6+px) {
            margin: 20px 0 40px 0;
        }
    }
    .swiperWrap {
        position: relative;
        .card {
            width: 100%;
            height: 100%;
            position: relative;
            background: #FFFFFF;
            font-family: $roboto;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.7);
            background: #FFFFFF;
            box-shadow: 0px 0px 24px rgba(207, 207, 207, 0.25);
            border-radius: 10px;
            padding: 40px 40px 60px 40px;
            @media(max-width: $md1+px) {
                padding: 40px 40px 50px 40px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                padding: 20px 20px 40px 20px;
            }
            &Header {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                .cardIcon {
                    width: 60px;
                    @media(max-width: $md6+px) {
                        width: 40px;
                    }
                }
                .cardTitle {
                    font-weight: 400;
                }
            }
            &Text {
                margin-top: 20px;
            }
        }
        :global(.homeSeeWhySwiper) {
            height: 100%;
            overflow: visible;
        }
        :global(.homeSeeWhySwiperSlide) {
            height: auto!important;
        }
        .navigation {
            position: absolute;
            z-index: 10;
            top: -100px;
            right: 0;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            @media(max-width: $md1+px) {
                top: -90px;
            }
            @media(max-width: $md4+px) {
                position: relative;
                top: unset;
                right: unset;
                justify-content: center;
                gap: 14px;
                margin-top: 20px;
            }
            @media(max-width: $md6+px) {
                margin-top: 30px;
                justify-content: center;
            }
            .nav {
                width: 40px;
                opacity: 0.3;
                transition: all .3s;
                cursor: pointer;
                @media(max-width: $md1+px) {
                    width: 30px;
                }
                &:hover {
                    opacity: 1;
                }
                &_left {
                    transform: rotate(180deg);
                }
            }
        }
    }
}