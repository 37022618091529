@import '../../../styles/var';

.gotQuestions {
    position: relative;
    .title {

    }
    .subtitle {
        color: #0566EC;
        font-weight: 600;
        margin: 10px 0 60px 0;
        @media(max-width: $md4+px) {
            margin: 6px 0 40px 0;
        }
    }
    .tabs { 
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media(max-width: $md4+px) {
            margin-top: 40px;
        }
        .tab { 
            cursor: pointer;
            position: relative;
            padding: 25px 100px;
            background: #FFFFFF;
            border-radius: 20px;
            @media(max-width: $md1+px) {
                padding: 20px 80px;
            }
            @media(max-width: $md3+px) {
                padding: 20px 40px;
            }
            @media(max-width: $md6+px) {
                padding: 13px 20px;
                border-radius: 10px;
            }
            &Active {
                .tabContent {
                    display: block;
                }
                .tabStatus {
                    &::before {
                        transform: rotate(90deg);
                    }
                }
            }
            &Header {
                display: grid;
                grid-template-columns: 1fr 40px;
                gap: 50px;
                align-items: center;
                @media(max-width: $md1+px) {
                    grid-template-columns: 1fr 30px;
                }
                .tabTitle {
                    font-family: $roboto;
                    font-weight: 400;
                }
                .tabStatus {
                    position: relative;
                    width: 40px;
                    height: 40px;
                    border: 1px solid #0566EC;
                    border-radius: 100%;
                    @media(max-width: $md1+px) {
                        width: 30px;
                        height: 30px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 20px;
                        top: calc(50% - 10px);
                        left: calc(50% - 1px);
                        background: #0566EC;
                        transition: all .2s;
                        @media(max-width: $md1+px) {
                            height: 14px;
                            top: calc(50% - 7px);
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 20px;
                        left: calc(50% - 10px);
                        top: calc(50% - 1px);
                        background: #0566EC;
                        @media(max-width: $md1+px) {
                            width: 14px;
                            left: calc(50% - 7px);
                        }
                    }
                }
            }
            &Content {
                display: none;
                margin-top: 10px;
                .tabText {
                    max-width: 1153px;
                    @media(max-width: $md1+px) {
                        max-width: 900px;
                    }
                    @media(max-width: $md3+px) {
                        max-width: 90%;
                    }
                    @media(max-width: $md6+px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}